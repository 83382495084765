<template>
  <form @submit.prevent="handleSearch">
    <div class="row mt-3 d-flex justify-content-end">
      <div class="col-12 col-md-2 my-1">
        <argon-select
          id="search-user-role"
          v-model="$parent.searchParams.role"
          :options="[
            { value: '', label: 'User role' },
            { value: 'Marketer', label: 'Marketer' },
            { value: 'Manager', label: 'Manager' },
            { value: 'Admin', label: 'Admin' },
          ]"
          :first-disabled="false"
          :use-choices="false"
        />
      </div>
      <div class="col-12 col-md-5 my-1">
        <div class="d-flex justify-content-between">
          <argon-input
            v-model="$parent.searchParams.query"
            class="me-2 w-100"
            placeholder="ID, Name, Email"
          />
          <button class="btn btn-primary" type="submit" :disabled="loading">
            <i v-if="loading" class="fa fa-refresh fa-spin" />
            <i v-else class="fas fa-search" />
          </button>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import ArgonInput from '@/components/ArgonInput.vue';
import ArgonSelect from '@/components/ArgonSelect.vue';
import router from '@/router';
import removeEmptyFields from '@/utils/removeEmptyFields';
import { parseQueryString } from '@/utils/queryString';

export default {
  name: 'UserFilters',
  components: { ArgonSelect, ArgonInput },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    async handleSearch() {
      const search = removeEmptyFields(this.$parent.searchParams);
      const queryString = parseQueryString(search);
      this.$parent.currentPage = 1;
      router.push(`${window.location.pathname}?page=1&${queryString}`);
      this.loading = true;
      await this.$parent.fetchData();
      this.loading = false;
    },
  },
};
</script>
