<template>
  <div class="py-4 px-1 px-sm-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="pb-0 card-header">
            <div class="row">
              <div class="col-sm-4 col-xxl-6">
                <h5 class="mb-0 text-center text-sm-start">{{ $route.name }}</h5>
              </div>
              <div class="col-sm-8 col-xxl-6">
                <div class="d-flex justify-content-center justify-content-sm-end mt-4 mt-sm-0">
                  <div v-if="store.role !== 'Marketer'">
                    <router-link
                      to="/products/add"
                      class="mb-0 mx-1 btn bg-gradient-primary btn-sm"
                    >
                      +&nbsp; New Product
                    </router-link>
                  </div>
                  <div v-if="store.role !== 'Marketer'">
                    <button
                      v-if="store.env === 'dev'"
                      type="button"
                      class="mb-0 btn btn-outline-primary btn-sm d-none d-lg-block"
                      data-bs-toggle="modal"
                      data-bs-target="#import"
                    >
                      Import
                    </button>
                    <div id="import" class="modal fade" tabindex="-1" aria-hidden="true">
                      <div class="modal-dialog mt-lg-10">
                        <div class="modal-content">
                          <div class="modal-header">
                            <h5 id="ModalLabel" class="modal-title">Import CSV</h5>
                            <i class="fas fa-upload ms-3"></i>
                            <button
                              type="button"
                              class="btn-close"
                              data-bs-dismiss="modal"
                              aria-label="Close"
                            ></button>
                          </div>
                          <div class="modal-body">
                            <p>You can browse your computer for a file.</p>
                            <input type="file" class="mb-3 form-control" />
                            <div class="form-check">
                              <input
                                id="importCheck"
                                class="form-check-input"
                                type="checkbox"
                                value=""
                                checked=""
                              />
                              <label class="custom-control-label" for="importCheck">
                                I accept the terms and conditions
                              </label>
                            </div>
                          </div>
                          <div class="modal-footer">
                            <button
                              type="button"
                              class="btn bg-gradient-secondary btn-sm"
                              data-bs-dismiss="modal"
                            >
                              Close
                            </button>
                            <button type="button" class="btn bg-gradient-primary btn-sm">
                              Upload
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-if="store.role !== 'Marketer'">
                    <router-link
                      to="/reports"
                      class="mb-0 mx-1 btn btn-outline-primary btn-sm export d-none d-lg-block"
                    >
                      Export
                    </router-link>
                  </div>
                  <div>
                    <button
                      class="mb-0 btn btn-outline-primary btn-sm"
                      @click="showFilters = !showFilters"
                    >
                      <i class="fas fa-filter"></i>
                      Filters
                      <i v-if="showFilters" class="ms-1 fas fa-angle-up"></i>
                      <i v-else class="ms-1 fas fa-angle-down"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <ProductFilters v-if="showFilters" />
          </div>

          <div class="px-0 pb-0 card-body">
            <div class="table-responsive">
              <table id="products-list" class="table align-items-center mb-0">
                <thead class="thead-light">
                  <tr>
                    <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                      Product
                    </th>
                    <th
                      colspan="3"
                      class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                    >
                      Details
                    </th>
                    <th
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 pe-5 pe-sm-0"
                    >
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-show="loading && !store.products[status].length">
                    <td colspan="8">
                      <div class="d-flex justify-content-center">
                        <div class="spinner-border text-primary" role="status">
                          <span class="sr-only">Loading...</span>
                        </div>
                      </div>
                    </td>
                  </tr>

                  <tr v-show="!loading && !store.products[status].length">
                    <td colspan="8">
                      <div class="d-flex justify-content-center">
                        <p class="text-monospace">No data found!</p>
                      </div>
                    </td>
                  </tr>

                  <tr v-for="product in store.products[status]" :key="product._id">
                    <td class="ps-4 py-3">
                      <div class="product-image-container">
                        <img
                          class="product-image"
                          :src="`${parseImageUrl(product.product_image)}`"
                        />
                        <i
                          v-show="product.manager?.isPrime"
                          title="This user has a prime account"
                          class="fas fa-crown prime-type"
                          style="color: gold"
                        ></i>
                      </div>
                    </td>
                    <td>
                      <p class="text-sm my-1" :title="product.keywords">
                        <strong>Keywords:</strong>
                        {{ actions.shortenString(product.keywords) }}
                      </p>
                      <p class="text-sm my-1">
                        <strong>Marketplace:</strong>
                        {{ country(product.amz_marketplace) }}
                        <span v-show="product.market_type !== 'Amazon'">
                          {{ product.market_type }}
                        </span>
                      </p>
                      <p class="text-sm my-1">
                        <strong>Review Type:</strong>
                        {{ product.review_type }}
                      </p>
                    </td>
                    <td>
                      <p class="text-sm my-1">
                        <strong>MPIN#:</strong>
                        {{ product.product_id }}
                      </p>
                      <p v-if="['Admin', 'Manager'].includes(store.role)" class="text-sm my-1">
                        <strong>Seller Code:</strong>
                        {{ product.seller_code || 'N/A' }}
                      </p>
                      <p v-else class="text-sm my-1" :title="product.sold_by">
                        <strong>Sold By:</strong>
                        {{ actions.shortenString(product.sold_by) }}
                      </p>
                      <p class="text-sm my-1" :title="product.brand_name">
                        <strong>Brand Name:</strong>
                        {{ actions.shortenString(product.brand_name) }}
                      </p>
                    </td>

                    <td v-if="store.role !== 'Marketer'">
                      <p class="text-sm my-1">
                        <strong>Sales Limit:</strong>
                        {{ product.remaining_daily_limit }} / {{ product.remaining_total_limit }}
                      </p>
                      <p v-if="store.role === 'Admin'" class="text-sm my-1">
                        <strong>Manager:&nbsp;</strong>
                        <router-link :to="`/users/${product.manager?.user_id}`">
                          {{ product.manager?.first_name }} {{ product.manager?.last_name }}
                        </router-link>
                        <WALink :phone="product.manager?.whatsapp_number" />
                      </p>
                      <p v-else class="text-sm my-1" :title="product.sold_by">
                        <strong>Sold By:</strong>
                        {{ actions.shortenString(product.sold_by) }}
                      </p>
                      <p v-if="product.status === 'Active'" class="text-sm my-1">
                        <strong>Added On:</strong>
                        {{ new Date(product.createdAt).toLocaleString() }}
                      </p>
                      <p v-else class="text-sm my-1">
                        <strong>Status:&nbsp;</strong>
                        <product-badge :sm="true" :value="product.status" />
                      </p>
                    </td>

                    <td v-else>
                      <p class="text-sm my-1">
                        <strong>Sales Limit:</strong>
                        {{ product.remaining_daily_limit }} / {{ product.remaining_total_limit }}
                      </p>
                      <p class="text-sm my-1">
                        <strong>Price:</strong>
                        {{ decodeHtmlEntity(currencies[product.amz_marketplace].icon)
                        }}{{ product.price }}
                      </p>
                      <p class="text-sm my-1">
                        <strong>Manager:&nbsp;</strong>
                        <router-link :to="`/users/${product.manager?.user_id}`">
                          {{ product.manager?.first_name }} {{ product.manager?.last_name }}
                        </router-link>
                        <WALink :phone="product.manager?.whatsapp_number" />
                      </p>
                    </td>

                    <td v-if="store.role !== 'Marketer'" class="text-sm">
                      <router-link :to="`/products/${product.product_id}`" title="View product">
                        <i class="fas fa-eye text-secondary"></i>
                      </router-link>
                      <router-link
                        :to="`/products/${product.product_id}/?edit=true`"
                        title="Edit product"
                        class="mx-3"
                      >
                        <i class="fas fa-edit text-secondary"></i>
                      </router-link>

                      <span
                        v-if="product.status === 'Active'"
                        title="Archive Product"
                        @click="archiveProduct(product._id)"
                      >
                        <i
                          class="text-secondary"
                          :class="
                            archiveLoading[product._id]
                              ? 'fa fa-refresh fa-spin'
                              : 'cursor-pointer fas fa-archive'
                          "
                        />
                      </span>

                      <span v-else title="UnArchive Product" @click="activateProduct(product)">
                        <i
                          class="text-secondary"
                          :class="
                            activateLoading[product._id]
                              ? 'fa fa-refresh fa-spin'
                              : 'cursor-pointer fas fa-trash-restore'
                          "
                        />
                      </span>
                    </td>
                    <td v-else class="text-sm text-center">
                      <div class="dropdown">
                        <span
                          class="cursor-pointer"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <i class="fas fa-ellipsis-v text-secondary"></i>
                        </span>
                        <ul class="dropdown-menu">
                          <li>
                            <a
                              class="dropdown-item"
                              href="#"
                              @click.prevent="downloadProductImage(product)"
                            >
                              <i class="fas fa-download text-secondary me-2"></i>
                              Download Picture
                            </a>
                          </li>
                          <li>
                            <a
                              class="dropdown-item"
                              href="#"
                              @click.prevent="openAmazonLink(product)"
                            >
                              <i class="fas fa-external-link-alt text-secondary me-2"></i>
                              Open AMZ Link
                            </a>
                          </li>
                          <li>
                            <a class="dropdown-item" href="#" @click.prevent="copyInfo(product)">
                              <i class="fas fa-copy text-secondary me-2"></i>
                              Copy Info
                            </a>
                          </li>
                          <li>
                            <router-link
                              class="dropdown-item"
                              :to="`/products/${product.product_id}`"
                            >
                              <i class="fas fa-eye text-secondary me-2"></i>
                              View Product
                            </router-link>
                          </li>
                          <li>
                            <a
                              class="dropdown-item"
                              href="#"
                              @click.prevent="reserveProduct(product)"
                              :class="{ disabled: reserveLoading[product._id] }"
                            >
                              <i
                                class="text-secondary me-2"
                                :class="
                                  reserveLoading[product._id]
                                    ? 'fa fa-refresh fa-spin'
                                    : 'fas fa-calendar-check'
                                "
                              ></i>
                              Reserve Product
                            </a>
                          </li>
                        </ul>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <pagination
              :total-count="totalItems"
              :current-page="currentPage"
              :on-page-change="pageChange"
              :page-size="20"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// Libs
import { useRoute } from 'vue-router';
import to from 'await-to-js';
import router from '@/router';
import { useToast } from 'vue-toastification';

// Components
import country from '@/utils/country';
import Pagination from '@/components/Pagination.vue';
import currencies, { decodeHtmlEntity } from '../../utils/currencies';
import ProductBadge from '@/components/ProductBadge.vue';
import ProductFilters from '@/views/products/components/ProductFilters.vue';
import WALink from '@/components/WALink.vue';

// Utils
import removeEmptyFields from '@/utils/removeEmptyFields';
import { parseQueryString } from '@/utils/queryString';
const toast = useToast();

export default {
  name: 'ProductsList',
  components: { WALink, ProductFilters, ProductBadge, Pagination },
  inject: ['store', 'actions'],
  data() {
    const { query, name } = useRoute();
    return {
      currencies,
      mediaUrl: process.env.VUE_APP_MEDIA_URL,
      loading: false,
      archiveLoading: {},
      reserveLoading: {},
      activateLoading: {},
      currentPage: parseInt(query.page) || 1,
      totalItems: 0,
      status: name.split(' ')[0],
      showFilters: true,
      searchParams: {
        amz_marketplace: query.amz_marketplace || '',
        review_type: query.review_type || '',
        category: query.category || '',
        query: query.query || '',
      },
    };
  },
  watch: {
    '$route.name'(name) {
      this.status = name.split(' ')[0];
      this.currentPage = 1;
      this.showFilters = true;
      this.searchParams = {
        amz_marketplace: '',
        review_type: '',
        category: '',
        query: '',
      };
      this.fetchData();
    },
    showFilters(newVal) {
      const search = removeEmptyFields({ ...this.searchParams });

      if (!newVal && Object.keys(search).length) {
        this.clearSearchFilters();
      }
    },
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    decodeHtmlEntity,
    async fetchData(page = this.currentPage) {
      const search = removeEmptyFields({ ...this.searchParams });
      const queryString = parseQueryString(search);

      this.loading = true;

      const [, data] = await to(
        this.actions.api('get', `/products/?status=${this.status}&page=${page}&${queryString}`),
      );

      this.loading = false;

      if (data?.success) {
        this.store.products[this.status] = data.products;
        this.totalItems = data.totalItems || 0;
      }
    },
    pageChange(page) {
      const search = removeEmptyFields({ ...this.searchParams });
      const queryString = parseQueryString(search);
      this.store.products[this.status] = [];
      this.currentPage = page;
      router.push(`${window.location.pathname}?page=${page}&${queryString}`);
      this.fetchData(page);
    },
    clearSearchFilters() {
      router.push(`${window.location.pathname}?page=1`);
      this.currentPage = 1;
      this.searchParams = {
        amz_marketplace: '',
        review_type: '',
        category: '',
        query: '',
      };
      this.fetchData(this.currentPage);
    },
    async archiveProduct(id) {
      if (this.archiveLoading[id]) return;

      this.archiveLoading[id] = true;
      const [err] = await to(this.actions.api('patch', `/products/archive/${id}`));
      this.archiveLoading[id] = false;

      if (!err) {
        toast.success('Product archived successfully');
        this.fetchData();
      }
    },
    async activateProduct(product) {
      if (this.activateLoading[product._id]) return;

      if (!product.daily_limit) {
        return toast.error('Please update limit to enable the product');
      }
      this.activateLoading[product._id] = true;
      const [err] = await to(this.actions.api('patch', `/products/activate/${product._id}`));
      this.activateLoading[product._id] = false;

      if (!err) {
        toast.success('Product activated successfully');
        this.fetchData();
      }
    },
    async reserveProduct(product) {
      if (this.reserveLoading[product._id]) return;

      if (!product.remaining_daily_limit) {
        return toast.error('Limit has been reached for this product');
      }

      this.reserveLoading[product._id] = true;
      const [err] = await to(this.actions.api('post', `/reservations/${product._id}`));
      this.reserveLoading[product._id] = false;

      if (!err) {
        toast.success('Product has been reserved');
        this.fetchData();
      }
    },
    copyInfo(product) {
      // Making Clipboard
      const clipboard = `MPIN: ${product?.product_id} \n\nKeywords: ${product.keywords} \n\nSold By: ${product.sold_by} \n\nBrand Name: ${product.brand_name}`;

      // Clipboard Function
      navigator.clipboard.writeText(clipboard).then(
        function () {
          toast.success('Text copied to your clipboard.');
          console.log('Async: Copying to clipboard was successful!');
        },
        function (err) {
          toast.error('Could not copy text.');
          console.error('Async: Could not copy text: ', err);
        },
      );
    },
    downloadProductImage(product) {
      if (!product.product_image) {
        return toast.error('No image available for download');
      }

      // Create a temporary anchor element
      const link = document.createElement('a');
      link.href = `${this.parseImageUrl(product.product_image)}`;
      link.download = `product_${product.product_id}.jpg`;
      link.target = '_blank';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    openAmazonLink(product) {
      if (!product.amz_link) {
        return toast.error('Amazon URL not available for this product');
      }

      // Open the Amazon URL in a new tab
      window.open(product.amz_link, '_blank');
    },
    country,
    parseImageUrl(url) {
      if (!url) return null;
      return url.startsWith('http') ? url : `${this.mediaUrl}${url}`;
    },
  },
};
</script>

<style>
.product-image {
  max-width: 100px;
  max-height: 100px;
  object-fit: cover;
}

.product-image-container {
  width: 100px;
  position: relative;
}

.prime-type {
  position: absolute;
  right: -10px;
}
</style>
