<template>
  <form @submit.prevent="handleSubmit">
    <div class="py-4 px-2 px-sm-4 container-fluid">
      <div class="row text-center text-sm-start">
        <div class="col-lg-6">
          <h4 class="text-white">Product MPIN# {{ productId }}</h4>
        </div>
        <div
          v-if="store.role !== 'Marketer'"
          class="text-right col-lg-6 d-flex flex-column justify-content-center"
        >
          <div class="mt-2 mb-0 me-lg-0 ms-lg-auto mt-lg-0">
            <button v-if="editing" type="submit" class="btn btn-light mx-2" :disabled="loading">
              <i v-if="loading" class="fa fa-refresh fa-spin" />
              <span v-else>Save</span>
            </button>
            <button
              type="button"
              class="btn btn-outline-white"
              @click="
                () => {
                  editing = !editing;
                  fetchData();
                }
              "
            >
              {{ editing ? 'Cancel' : 'Edit' }}
            </button>
          </div>
        </div>
      </div>

      <!--  Images  -->
      <div class="mt-4 row">
        <div class="col-lg-4">
          <!--   Product Image     -->
          <div class="card">
            <div class="card-body">
              <h5 class="font-weight-bolder">Product Image</h5>
              <div class="row">
                <div class="col-12">
                  <img
                    class="mt-3 shadow-lg w-100 border-radius-lg"
                    :src="`${parseImageUrl(product.product_image)}`"
                    alt="product_image"
                  />
                </div>
                <div v-if="store.role !== 'Marketer'" class="mt-5 col-12">
                  <div class="d-flex">
                    <label
                      class="mb-0 btn bg-gradient-primary btn-sm me-2"
                      htmlFor="editproduct-product_image"
                      title="Edit product image"
                    >
                      Edit
                    </label>
                  </div>
                </div>
                <input
                  v-if="store.role !== 'Marketer'"
                  id="editproduct-product_image"
                  type="file"
                  name="product_image"
                  accept=".png, .jpg, .jpeg, .webp, .gif"
                  hidden
                  @change="uploadImage"
                />
              </div>
            </div>
          </div>

          <!--   Amazon Screenshot     -->
          <div v-if="product.amz_screenshot || store.role !== 'Marketer'" class="mt-3 card">
            <div class="card-body">
              <h5 class="font-weight-bolder" :class="!product.amz_screenshot && 'text-center'">
                Amazon Screenshot
              </h5>
              <div v-if="product.amz_screenshot" class="row">
                <div class="col-12">
                  <img
                    class="mt-3 shadow-lg w-100 border-radius-lg"
                    :src="`${parseImageUrl(product.amz_screenshot)}`"
                    alt="product_image"
                  />
                </div>

                <div v-if="store.role !== 'Marketer'" class="mt-5 col-12">
                  <div class="d-flex">
                    <label
                      class="mb-0 btn bg-gradient-primary btn-sm me-2"
                      htmlFor="editproduct-amz_screenshot"
                      title="Edit AMZ screenshot"
                    >
                      Edit
                    </label>
                    <button
                      class="mb-0 btn btn-outline-dark btn-sm"
                      type="button"
                      name="button"
                      @click="removeImage('amz_screenshot')"
                    >
                      Remove
                    </button>
                  </div>
                </div>
              </div>
              <div v-else-if="store.role !== 'Marketer'" class="row mt-3">
                <div class="d-flex justify-content-center">
                  <label
                    class="mb-0 btn bg-gradient-primary btn-lg me-2"
                    htmlFor="editproduct-amz_screenshot"
                    title="Add AMZ screenshot"
                  >
                    Add
                  </label>
                </div>
              </div>
              <input
                v-if="store.role !== 'Marketer'"
                id="editproduct-amz_screenshot"
                type="file"
                name="amz_screenshot"
                accept=".png, .jpg, .jpeg, .webp, .gif"
                hidden
                @change="uploadImage"
              />
            </div>
          </div>
        </div>

        <div class="mt-4 col-lg-8 mt-lg-0">
          <!--   Basic Info     -->
          <div class="card">
            <div class="card-body">
              <h5 class="font-weight-bolder">Product Information</h5>
              <p v-show="editing" class="mb-0 text-sm">Fields with * are mandatory</p>

              <div class="row mt-3">
                <div class="col-md-6 my-1">
                  <label class="form-label" htmlFor="editproduct-amz-marketplace">
                    AMZ Marketplace {{ editing ? '*' : '' }}
                  </label>
                  <argon-select
                    id="editproduct-amz-marketplace"
                    v-model="product.amz_marketplace"
                    :use-choices="false"
                    :is-disabled="!editing"
                    :options="marketPlaces"
                  />
                </div>
                <div class="col-md-6 my-1">
                  <label class="form-label" htmlFor="editproduct-market-type">
                    Market Type {{ editing ? '*' : '' }}
                  </label>
                  <argon-select
                    id="editproduct-market-type"
                    v-model="product.market_type"
                    :options="[
                      { value: 'Amazon', label: 'Amazon' },
                      { value: 'Walmart', label: 'Walmart' },
                      { value: 'TikTok', label: 'TikTok' },
                      { value: 'Ottu', label: 'Ottu' },
                    ]"
                    :use-choices="false"
                    :is-disabled="!editing"
                  />
                </div>
              </div>
              <div class="row">
                <div class="col-12 my-1">
                  <label class="form-label" htmlFor="editproduct-keywords">
                    Keywords {{ editing ? '*' : '' }}
                  </label>
                  <argon-input
                    id="editproduct-keywords"
                    v-model="product.keywords"
                    type="text"
                    placeholder="Enter keywords"
                    :is-disabled="!editing"
                  />
                </div>
              </div>
              <div class="row">
                <div class="col-md-6 my-1">
                  <label class="form-label" htmlFor="editproduct-soldby">
                    Sold By {{ editing ? '*' : '' }}
                  </label>
                  <argon-input
                    id="editproduct-soldby"
                    v-model="product.sold_by"
                    type="text"
                    placeholder="Enter AMZ sold by"
                    :is-disabled="!editing"
                  />
                </div>
                <div class="col-md-6 my-1">
                  <label class="form-label" htmlFor="editproduct-brand-name">
                    Brand Name {{ editing ? '*' : '' }}
                  </label>
                  <argon-input
                    id="editproduct-brand-name"
                    v-model="product.brand_name"
                    type="text"
                    placeholder="Enter AMZ brand name"
                    :is-disabled="!editing"
                  />
                </div>
              </div>
              <div class="row">
                <div class="col-md-6 my-1">
                  <label class="form-label" htmlFor="editproduct-price">Product Price</label>
                  <argon-input
                    id="editproduct-price"
                    v-model="product.price"
                    type="number"
                    placeholder="Enter product price"
                    :is-disabled="!editing"
                    icon-dir="left"
                    :icon="currencies[product.amz_marketplace]?.icon"
                  />
                </div>
                <div class="col-md-6 my-1">
                  <label class="form-label" htmlFor="editproduct-product-category">
                    Product Category {{ editing ? '*' : '' }}
                  </label>
                  <argon-select
                    id="editproduct-product-category"
                    v-model="product.category"
                    :use-choices="false"
                    :is-disabled="!editing"
                    :options="categories"
                  />
                </div>
              </div>
              <div class="row">
                <div class="col-md-6 my-1">
                  <label class="form-label" htmlFor="editproduct-amz-asin">AMZ ASIN</label>
                  <argon-input
                    id="editproduct-amz-asin"
                    v-model="product.amz_asin"
                    type="text"
                    placeholder="Enter AMZ ASIN"
                    :is-disabled="!editing"
                  />
                </div>
                <div class="col-md-6 my-1">
                  <label class="form-label" htmlFor="editproduct-seller-code">
                    Seller Code&nbsp;
                    <span
                      v-if="editing"
                      class="text-primary cursor-pointer"
                      @click="custom_seller_code = !custom_seller_code"
                    >
                      {{
                        custom_seller_code || !seller_codes.length
                          ? '(Select from previous seller codes)'
                          : '(Add new seller code)'
                      }}
                    </span>
                  </label>
                  <argon-input
                    v-if="custom_seller_code || !seller_codes.length"
                    id="editproduct-seller-code"
                    v-model="product.seller_code"
                    type="text"
                    placeholder="Enter seller code"
                    :is-disabled="!editing"
                  />
                  <argon-select
                    v-else
                    id="editproduct-seller-code"
                    v-model="product.seller_code"
                    :use-choices="false"
                    :is-searchable="true"
                    :is-disabled="!editing"
                    :options="seller_codes"
                  />
                </div>
              </div>
              <div class="row">
                <div class="col-12 my-1">
                  <label class="form-label" htmlFor="editproduct-amz-link">AMZ Link</label>
                  <argon-input
                    id="editproduct-amz-link"
                    v-model="product.amz_link"
                    type="text"
                    placeholder="Enter AMZ Link"
                    :is-disabled="!editing"
                  />
                </div>
              </div>
            </div>
          </div>

          <!--    Commission Details    -->
          <div class="mt-3 card">
            <div class="card-body">
              <h5 class="font-weight-bolder">Commission Details</h5>
              <p v-show="editing" class="mb-0 text-sm">
                High commission policy: The product offering commission Rs1000 or above will be
                displayed as high commission product.
              </p>
              <div class="row mt-3">
                <div class="col-md-12 my-1">
                  <label class="form-label" htmlFor="editproduct-review-type">
                    Review Type {{ editing ? '*' : '' }}
                  </label>
                  <argon-select
                    id="editproduct-review-type"
                    v-model="product.review_type"
                    :use-choices="false"
                    :is-disabled="!editing"
                    :options="reviewTypes"
                  />
                </div>
              </div>

              <div
                v-if="
                  store.role === 'Marketer' &&
                  ['Feedback', 'Rating', 'No Review'].includes(product.review_type)
                "
              >
                <div class="col-12 my-1">
                  <label class="form-label" htmlFor="editproduct-text-review-commission">
                    Commission
                  </label>
                  <argon-input
                    id="editproduct-commission"
                    v-model="product.commission.marketer"
                    type="number"
                    icon-dir="left"
                    icon="&#8360;."
                    :is-disabled="!editing"
                    is-required
                  />
                </div>
              </div>

              <div v-else-if="store.role === 'Marketer'">
                <div class="row mt-3">
                  <div class="col-md-4 my-1">
                    <label class="form-label" htmlFor="editproduct-text-review-commission">
                      Text Review Commission
                    </label>
                    <argon-input
                      id="editproduct-text-review-commission"
                      v-model="product.commission.text.marketer"
                      type="number"
                      icon-dir="left"
                      icon="&#8360;."
                      :is-disabled="!editing"
                    />
                  </div>
                  <div class="col-md-4 my-1">
                    <label class="form-label" htmlFor="editproduct-picture-review-commission">
                      Picture Review Commission
                    </label>
                    <argon-input
                      id="editproduct-picture-review-commission"
                      v-model="product.commission.picture.marketer"
                      type="number"
                      icon-dir="left"
                      icon="&#8360;."
                      :is-disabled="!editing"
                    />
                  </div>
                  <div class="col-md-4 my-1">
                    <label class="form-label" htmlFor="editproduct-video-review-commission">
                      Video Review Commission
                    </label>
                    <argon-input
                      id="editproduct-video-review-commission"
                      v-model="product.commission.video.marketer"
                      type="number"
                      icon-dir="left"
                      icon="&#8360;."
                      :is-disabled="!editing"
                    />
                  </div>
                </div>
              </div>

              <div
                v-else-if="['Feedback', 'Rating', 'No Review'].includes(product.review_type)"
                class="row mt-3"
              >
                <div class="col-md-6 my-1">
                  <label class="form-label" htmlFor="editproduct-text-review-commission">
                    Commission (min. Rs.{{ commissionStructure[product.review_type].marketer }})
                  </label>
                  <argon-input
                    id="editproduct-commission"
                    v-model="product.commission.marketer"
                    type="number"
                    icon-dir="left"
                    icon="&#8360;."
                    :is-disabled="!editing"
                    is-required
                  />
                </div>
                <div class="col-md-6 my-1">
                  <label class="form-label" htmlFor="editproduct-review-portal">
                    Portal Fee (GlobeMart)
                  </label>
                  <argon-input
                    id="editproduct-text-review-portal"
                    v-model="product.commission.portal"
                    type="number"
                    icon-dir="left"
                    icon="&#8360;."
                    is-required
                    is-disabled
                  />
                </div>
              </div>

              <div v-else>
                <div class="row mt-3">
                  <div class="col-md-6 my-1">
                    <label class="form-label" htmlFor="editproduct-text-review-commission">
                      Text Review Commission (min. Rs.{{
                        commissionStructure[product.review_type]?.text?.marketer
                      }})
                    </label>
                    <argon-input
                      id="editproduct-text-review-commission"
                      v-model="product.commission.text.marketer"
                      type="number"
                      icon-dir="left"
                      icon="&#8360;."
                      :is-disabled="!editing"
                    />
                  </div>
                  <div class="col-md-6 my-1">
                    <label class="form-label" htmlFor="editproduct-text-review-portal">
                      Portal Fee (GlobeMart)
                    </label>
                    <argon-input
                      id="editproduct-text-review-portal"
                      v-model="product.commission.text.portal"
                      type="number"
                      icon-dir="left"
                      icon="&#8360;."
                      is-disabled
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6 my-1">
                    <label class="form-label" htmlFor="editproduct-picture-review-commission">
                      Picture Review Commission (min. Rs.{{
                        commissionStructure[product.review_type]?.picture?.marketer
                      }})
                    </label>
                    <argon-input
                      id="editproduct-picture-review-commission"
                      v-model="product.commission.picture.marketer"
                      type="number"
                      icon-dir="left"
                      icon="&#8360;."
                      :is-disabled="!editing"
                    />
                  </div>
                  <div class="col-md-6 my-1">
                    <label class="form-label" htmlFor="editproduct-picture-review-portal">
                      Portal Fee (GlobeMart)
                    </label>
                    <argon-input
                      id="editproduct-picture-review-portal"
                      v-model="product.commission.picture.portal"
                      type="number"
                      icon-dir="left"
                      icon="&#8360;."
                      is-disabled
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6 my-1">
                    <label class="form-label" htmlFor="editproduct-video-review-commission">
                      Video Review Commission (min. Rs.{{
                        commissionStructure[product.review_type]?.video?.marketer
                      }})
                    </label>
                    <argon-input
                      id="editproduct-video-review-commission"
                      v-model="product.commission.video.marketer"
                      type="number"
                      icon-dir="left"
                      icon="&#8360;."
                      :is-disabled="!editing"
                    />
                  </div>
                  <div class="col-md-6 my-1">
                    <label class="form-label" htmlFor="editproduct-video-review-portal">
                      Portal Fee (GlobeMart)
                    </label>
                    <argon-input
                      id="editproduct-video-review-portal"
                      v-model="product.commission.video.portal"
                      type="number"
                      icon-dir="left"
                      icon="&#8360;."
                      is-disabled
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!--   Additional Details     -->
          <div class="mt-3 card">
            <div class="card-body">
              <h5 class="font-weight-bolder">Additional Details</h5>
              <div class="row">
                <div class="col-md-6 my-1">
                  <label class="form-label" htmlFor="editproduct-daily-limit">
                    Daily Sales Limit {{ editing ? '*' : '' }}
                  </label>
                  <argon-input
                    id="editproduct-daily-limit"
                    v-model="product.daily_limit"
                    type="number"
                    :is-disabled="!editing"
                  />
                </div>
                <div class="col-md-6 my-1">
                  <label class="form-label" htmlFor="editproduct-total-limit">
                    Total Sales Limit {{ editing ? '*' : '' }}
                  </label>
                  <argon-input
                    id="editproduct-total-limit"
                    v-model="product.total_limit"
                    type="number"
                    :is-disabled="!editing"
                  />
                </div>
              </div>
              <div class="row my-1">
                <div class="col-12 col-md-6 my-2">
                  <label class="my-0">Expensive Product</label>
                  <p class="text-xs form-text text-muted ms-1 my-1">
                    If you select this, the product won't show to the new members.
                  </p>
                  <div class="form-check form-switch ms-1 my-0">
                    <input
                      class="form-check-input"
                      v-model="product.expensive"
                      type="checkbox"
                      :disabled="!editing"
                    />
                  </div>
                </div>
                <div class="col-12 col-md-6 my-2">
                  <label class="my-0">Prime Only</label>
                  <p class="text-xs form-text text-muted ms-1 my-1">
                    Show only to prime marketers.
                  </p>
                  <div class="form-check form-switch ms-1 my-0">
                    <input
                      class="form-check-input"
                      v-model="product.prime_only"
                      type="checkbox"
                      checked
                      :disabled="!editing"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!--    General Rules    -->
          <div class="mt-3 card">
            <div class="card-body">
              <h5 class="font-weight-bolder">General Rules</h5>
              <RuleCard
                v-for="(value, i) in product.general_rules"
                :key="i"
                :idx="i"
                name="general_rules"
                :value="value"
                :hide-cross="!editing"
              />

              <div v-show="editing" class="row mt-3">
                <div class="col-9 col-md-11">
                  <argon-input
                    type="text"
                    v-model="general_rules"
                    placeholder="Add another point"
                  />
                </div>
                <div class="col-3 col-md-1">
                  <argon-button class="w-100" @click="addRule('general_rules')">+</argon-button>
                </div>
              </div>
            </div>
          </div>

          <!--   Instructions for Marketer     -->
          <div class="mt-3 card">
            <div class="card-body">
              <h5 class="font-weight-bolder">Instructions for Marketer</h5>

              <RuleCard
                v-for="(value, i) in product.instructions"
                :key="i"
                :idx="i"
                name="instructions"
                :value="value"
                :hide-cross="!editing"
              />

              <div v-show="editing" class="row mt-3">
                <div class="col-9 col-md-11">
                  <argon-input type="text" v-model="instructions" placeholder="Add another point" />
                </div>
                <div class="col-3 col-md-1">
                  <argon-button class="w-100" @click="addRule('instructions')">+</argon-button>
                </div>
              </div>
            </div>
          </div>

          <!--    Commission Policy    -->
          <div class="mt-3 card">
            <div class="card-body">
              <h5 class="font-weight-bolder">Commission Policy</h5>

              <RuleCard
                v-for="(value, i) in product.commission_policy"
                :key="i"
                name="commission_policy"
                :idx="i"
                :value="value"
                :hide-cross="!editing"
              />

              <div v-show="editing" class="row mt-3">
                <div class="col-9 col-md-11">
                  <argon-input
                    type="text"
                    v-model="commission_policy"
                    placeholder="Add another point"
                  />
                </div>
                <div class="col-3 col-md-1">
                  <argon-button class="w-100" @click="addRule('commission_policy')">+</argon-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
// Libs
import { useRoute } from 'vue-router';
import { useToast } from 'vue-toastification';
import router from '@/router';
import to from 'await-to-js';

// Components
import ArgonSelect from '@/components/ArgonSelect.vue';
import ArgonInput from '@/components/ArgonInput.vue';
import RuleCard from '@/components/Rule.vue';
import ArgonButton from '@/components/ArgonButton.vue';

// Utils
import currencies from '@/utils/currencies';
import marketPlaces from '@/utils/marketPlaces';
import categories from '@/utils/categories';
import reviewTypes from '@/utils/reviewTypes';
import validateCommission, { commissionStructure } from '@/utils/commissionValidator';
import removeEmptyFields from '@/utils/removeEmptyFields';

// Init
const toast = useToast();

export default {
  name: 'ViewProduct',
  components: { ArgonButton, RuleCard, ArgonInput, ArgonSelect },
  inject: ['store', 'actions'],
  watch: {
    'product.review_type'(newVal, oldVal) {
      const product = this.product;
      const { review_type } = this.product;

      // To not update if the review type is changed from the API
      if (!oldVal) {
        return;
      }

      if (['Feedback', 'Rating', 'No Review'].includes(product.review_type)) {
        this.product.commission = {
          marketer: commissionStructure[review_type].marketer || '',
          portal: commissionStructure[review_type].portal || '',
        };
      } else {
        this.product.commission = {
          text: {
            marketer: commissionStructure[review_type].text.marketer || '',
            portal: commissionStructure[review_type].text.portal || '',
          },
          picture: {
            marketer: commissionStructure[review_type].picture.marketer || '',
            portal: commissionStructure[review_type].picture.portal || '',
          },
          video: {
            marketer: commissionStructure[review_type].video.marketer || '',
            portal: commissionStructure[review_type].video.portal || '',
          },
        };
      }
    },
    'store.user'() {
      this.setSellerCodes();
    },
  },
  data() {
    const { query, params } = useRoute();
    return {
      loading: false,
      currencies,
      mediaUrl: process.env.VUE_APP_MEDIA_URL,
      productId: params.productId,
      editing: query.edit && this.store.role !== 'Marketer',
      custom_seller_code: false,
      marketPlaces,
      categories,
      reviewTypes,
      commissionStructure,
      seller_codes: [],
      product: {
        amz_marketplace: '',
        market_type: '',
        category: '',
        seller_code: '',
        amz_asin: '',
        amz_link: '',
        sold_by: '',
        brand_name: '',
        price: '',
        keywords: '',
        product_image: '',
        amz_screenshot: '',
        review_type: '',
        commission: {
          text: {
            marketer: commissionStructure.Review.text.marketer,
            portal: commissionStructure.Review.text.portal,
          },
          picture: {
            marketer: commissionStructure.Review.picture.marketer,
            portal: commissionStructure.Review.picture.portal,
          },
          video: {
            marketer: commissionStructure.Review.video.marketer,
            portal: commissionStructure.Review.video.portal,
          },
        },
        daily_limit: '',
        total_limit: '',
        expensive: false,
        prime_only: false,
        general_rules: [],
        instructions: [],
        commission_policy: [],
      },
      general_rules: '',
      instructions: '',
      commission_policy: '',
    };
  },
  mounted() {
    this.setSellerCodes();
    this.fetchData();
  },
  methods: {
    async fetchData() {
      const [, data] = await to(this.actions.api('get', `/products/${this.productId}`));

      if (!data?.product) {
        router.push('/dashboard');
        return toast.error('No product found');
      }

      const sellerCodeExists = this.seller_codes.filter(
        (_) => _.value === data.product.seller_code,
      ).length;

      console.log(sellerCodeExists);

      if (!sellerCodeExists) {
        this.seller_codes.push({
          label: data.product.seller_code,
          value: data.product.seller_code,
        });
      }
      this.product = { ...this.product, ...data.product };
    },
    async handleSubmit() {
      let data = removeEmptyFields({ ...this.product });

      const validated = [
        'amz_marketplace',
        'market_type',
        'category',
        'sold_by',
        'brand_name',
        'keywords',
        'product_image',
        'review_type',
        'daily_limit',
        'total_limit',
      ].every((val) => !!data[val]);

      if (!validated) {
        return toast.error('Fields with * are required');
      }

      if (parseInt(data.daily_limit) > parseInt(data.total_limit)) {
        return toast.error('Daily limit must be lesser than total limit');
      }

      try {
        await validateCommission(data.review_type, data.commission);
      } catch (err) {
        return toast.error(err.message);
      }

      this.loading = true;
      const [, res] = await to(this.actions.api('put', `/products/${this.product._id}`, data));
      this.loading = false;

      if (res?.product) {
        toast.success('Product has been updated');
        this.editing = false;
        this.store.user = res.user;
      }
    },
    async uploadImage(e) {
      if (!e.target.files?.length) {
        return;
      }

      const formData = new FormData();
      formData.append(e.target.name, e.target.files[0]);
      formData.append('field', e.target.name);

      const [, data] = await to(
        this.actions.api('put', `/products/update-image/${this.product._id}`, formData),
      );

      document.getElementById(e.target.id).value = '';

      if (data?.success) {
        toast.success('Picture updated successfully');
        this.fetchData();
      }
    },
    async removeImage(field) {
      const formData = new FormData();
      formData.append('field', field);

      const [, data] = await to(
        this.actions.api('put', `/products/update-image/${this.product._id}`, formData),
      );

      if (data?.success) {
        toast.success('Picture removed successfully');
        this.fetchData();
      }
    },
    setSellerCodes() {
      this.seller_codes = [];
      this.store.user?.other_data?.seller_codes?.forEach((code, idx) => {
        if (idx === 0) {
          this.seller_codes.push({ value: '', label: 'Select a seller code' });
        }
        this.seller_codes.push({ value: code, label: code });
      });
    },
    addRule(name) {
      if (this[name]) {
        this.product[name].push(this[name]);
        this[name] = '';
      }
    },
    removeRule(name, idx) {
      this.product[name].splice(idx, 1);
    },
    parseImageUrl(url) {
      if (!url) return null;
      return url.startsWith('http') ? url : `${this.mediaUrl}${url}`;
    },
  },
};
</script>
