<template>
  <div class="py-4 px-1 px-sm-4 container-fluid">
    <div class="row">
      <div class="mx-auto col-lg-9 col-12">
        <div class="mt-4 card card-body">
          <div class="d-flex justify-content-between align-items-center">
            <div>
              <h6 class="mb-0">New Product</h6>
              <p class="mb-0 text-sm">Create new product</p>
            </div>
            <div class="d-flex">
              <button
                type="button"
                class="btn btn-sm bg-gradient-info me-2"
                @click="showBulkImportModal = true"
              >
                <i class="fas fa-file-import me-2"></i>
                Bulk Import
              </button>
            </div>
          </div>
          <hr class="my-3 horizontal dark" />

          <!--    Basic Info      -->
          <div class="card card-body">
            <h6 class="mb-0">Basic Info</h6>
            <p class="mb-0 text-sm">Fields with * are mandatory</p>
            <hr class="my-3 horizontal dark" />
            <div class="row">
              <div class="col-md-6 my-1">
                <label class="form-label" htmlFor="addproduct-amz-marketplace">
                  AMZ Marketplace *
                </label>
                <argon-select
                  id="addproduct-amz-marketplace"
                  v-model="product.amz_marketplace"
                  :options="marketPlaces"
                  is-required
                />
              </div>
              <div class="col-md-6 my-1">
                <label class="form-label" htmlFor="addproduct-market-type">Market Type *</label>
                <argon-select
                  id="addproduct-market-type"
                  v-model="product.market_type"
                  :options="[
                    { value: 'Amazon', label: 'Amazon' },
                    { value: 'Walmart', label: 'Walmart' },
                    { value: 'TikTok', label: 'TikTok' },
                    { value: 'Ottu', label: 'Ottu' },
                  ]"
                  is-required
                />
              </div>
            </div>
            <div class="row">
              <div class="col-12 my-1">
                <label class="form-label" htmlFor="addproduct-keywords">Keywords *</label>
                <argon-input
                  id="addproduct-keywords"
                  v-model="product.keywords"
                  type="text"
                  placeholder="Enter keywords"
                  is-required
                />
              </div>
            </div>
            <div class="row">
              <div class="col-md-6 my-1">
                <label class="form-label" htmlFor="addproduct-soldby">Sold By *</label>
                <argon-input
                  id="addproduct-soldby"
                  v-model="product.sold_by"
                  type="text"
                  placeholder="Enter AMZ sold by"
                  is-required
                />
              </div>
              <div class="col-md-6 my-1">
                <label class="form-label" htmlFor="addproduct-brand-name">Brand Name *</label>
                <argon-input
                  id="addproduct-brand-name"
                  v-model="product.brand_name"
                  type="text"
                  placeholder="Enter AMZ brand name"
                  is-required
                />
              </div>
            </div>
            <div class="row">
              <div class="col-md-6 my-1">
                <label class="form-label" htmlFor="addproduct-price">Product Price</label>
                <argon-input
                  id="addproduct-price"
                  v-model="product.price"
                  type="number"
                  placeholder="Enter product price"
                  icon-dir="left"
                  :icon="currencies[product.amz_marketplace || 'United States'].icon"
                />
              </div>
              <div class="col-md-6 my-1">
                <label class="form-label" htmlFor="addproduct-product-category">
                  Product Category *
                </label>
                <argon-select
                  id="addproduct-product-category"
                  v-model="product.category"
                  :options="categories"
                  is-required
                />
              </div>
            </div>
            <div class="row">
              <div class="col-md-6 my-1">
                <label class="form-label" htmlFor="addproduct-amz-asin">AMZ ASIN</label>
                <argon-input
                  id="addproduct-amz-asin"
                  v-model="product.amz_asin"
                  type="text"
                  placeholder="Enter AMZ ASIN"
                />
              </div>
              <div class="col-md-6 my-1">
                <label class="form-label" htmlFor="addproduct-seller-code">
                  Seller Code&nbsp;(
                  <span
                    class="text-primary cursor-pointer"
                    @click="custom_seller_code = !custom_seller_code"
                  >
                    {{
                      custom_seller_code || !seller_codes.length
                        ? 'Select from previous seller codes'
                        : 'Add new seller code'
                    }}
                  </span>
                  )
                </label>
                <argon-input
                  v-if="custom_seller_code || !seller_codes.length"
                  id="addproduct-seller-code"
                  v-model="product.seller_code"
                  type="text"
                  placeholder="Enter seller code"
                />
                <argon-select
                  v-else
                  id="addproduct-seller-code"
                  v-model="product.seller_code"
                  :use-choices="false"
                  :is-searchable="true"
                  :options="seller_codes"
                />
              </div>
            </div>
            <div class="row"></div>
            <div class="row">
              <div class="col-12 my-1">
                <label class="form-label" htmlFor="addproduct-amz-link">AMZ Link</label>
                <argon-input
                  id="addproduct-amz-link"
                  v-model="product.amz_link"
                  type="text"
                  placeholder="Enter AMZ Link"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-md-6 my-1">
                <label class="form-label" htmlFor="addproduct-product-image">Product Image *</label>
                <argon-input
                  id="addproduct-product-image"
                  v-model="product.product_image"
                  type="file"
                  is-required
                />
              </div>
              <div class="col-md-6 my-1">
                <label class="form-label" htmlFor="addproduct-amz-screenshot">
                  Amazon Screenshot
                </label>
                <argon-input
                  id="addproduct-amz-screenshot"
                  v-model="product.amz_screenshot"
                  type="file"
                />
              </div>
            </div>
          </div>

          <!--    Commission Details      -->
          <div class="mt-4 card card-body">
            <h6 class="mb-0">Commission Details</h6>
            <p class="mb-0 text-sm">
              High commission policy: The product offering commission Rs1000 or above will be
              displayed as high commission product.
            </p>
            <hr class="my-3 horizontal dark" />
            <div class="row">
              <div class="col-md-6">
                <label class="form-label" htmlFor="addproduct-review-type">Review Type *</label>
                <argon-select
                  id="addproduct-review-type"
                  v-model="product.review_type"
                  :options="reviewTypes"
                  is-required
                />
              </div>
              <div
                v-if="!['Feedback', 'Rating', 'No Review'].includes(product.review_type)"
                class="col-md-6"
              >
                <label class="form-label" htmlFor="addproduct-commission-type">
                  Commission Type *
                </label>
                <argon-select
                  id="addproduct-commission-type"
                  v-model="product.commission_type"
                  :options="commissionTypes"
                  is-required
                />
              </div>
            </div>

            <div
              v-if="['Feedback', 'Rating', 'No Review'].includes(product.review_type)"
              class="row mt-3"
            >
              <div class="col-md-6 my-1">
                <label class="form-label" htmlFor="addproduct-commission">
                  Commission (min. Rs.{{ commissionStructure[product.review_type].marketer }})
                </label>
                <argon-input
                  id="addproduct-commission"
                  v-model="product.commission.marketer"
                  type="number"
                  icon-dir="left"
                  icon="&#8360;."
                  is-required
                  is-disabled
                />
              </div>
              <div class="col-md-6 my-1">
                <label class="form-label" htmlFor="addproduct-portal">Portal Fee (GlobeMart)</label>
                <argon-input
                  id="addproduct-portal"
                  v-model="product.commission.portal"
                  type="number"
                  icon-dir="left"
                  icon="&#8360;."
                  is-required
                  is-disabled
                />
              </div>
            </div>
            <div v-else>
              <div v-if="product.commission_type === 'Text'" class="row mt-3">
                <div class="col-md-6 my-1">
                  <label class="form-label" htmlFor="addproduct-text-review-commission">
                    Text Review Commission (min. Rs.{{
                      commissionStructure[product.review_type]?.text?.marketer
                    }})
                  </label>
                  <argon-input
                    id="addproduct-text-review-commission"
                    v-model="product.commission.text.marketer"
                    type="number"
                    icon-dir="left"
                    icon="&#8360;."
                    is-required
                    :is-disabled="product.review_type === 'Review'"
                  />
                </div>
                <div class="col-md-6 my-1">
                  <label class="form-label" htmlFor="addproduct-text-review-portal">
                    Portal Fee (GlobeMart)
                  </label>
                  <argon-input
                    id="addproduct-text-review-portal"
                    v-model="product.commission.text.portal"
                    type="number"
                    icon-dir="left"
                    icon="&#8360;."
                    is-required
                    is-disabled
                  />
                </div>
              </div>
              <div v-else-if="product.commission_type === 'Picture'" class="row mt-3">
                <div class="col-md-6 my-1">
                  <label class="form-label" htmlFor="addproduct-picture-review-commission">
                    Picture Review Commission (min. Rs.{{
                      commissionStructure[product.review_type]?.picture?.marketer
                    }}))
                  </label>
                  <argon-input
                    id="addproduct-picture-review-commission"
                    v-model="product.commission.picture.marketer"
                    type="number"
                    icon-dir="left"
                    icon="&#8360;."
                    is-required
                    :is-disabled="product.review_type === 'Review'"
                  />
                </div>
                <div class="col-md-6 my-1">
                  <label class="form-label" htmlFor="addproduct-picture-review-portal">
                    Portal Fee (GlobeMart)
                  </label>
                  <argon-input
                    id="addproduct-picture-review-portal"
                    v-model="product.commission.picture.portal"
                    type="number"
                    icon-dir="left"
                    icon="&#8360;."
                    is-disabled
                    is-required
                  />
                </div>
              </div>
              <div v-else-if="product.commission_type === 'Video'" class="row mt-3">
                <div class="col-md-6 my-1">
                  <label class="form-label" htmlFor="addproduct-video-review-commission">
                    Video Review Commission (min. Rs.{{
                      commissionStructure[product.review_type]?.video?.marketer
                    }})
                  </label>
                  <argon-input
                    id="addproduct-video-review-commission"
                    v-model="product.commission.video.marketer"
                    type="number"
                    icon-dir="left"
                    icon="&#8360;."
                    is-required
                    :is-disabled="product.review_type === 'Review'"
                  />
                </div>
                <div class="col-md-6 my-1">
                  <label class="form-label" htmlFor="addproduct-video-review-portal">
                    Portal Fee (GlobeMart)
                  </label>
                  <argon-input
                    id="addproduct-video-review-portal"
                    v-model="product.commission.video.portal"
                    type="number"
                    icon-dir="left"
                    icon="&#8360;."
                    is-disabled
                    is-required
                  />
                </div>
              </div>
            </div>
          </div>

          <!--    Additional Details      -->
          <div class="mt-4 card card-body">
            <h6 class="mb-0">Additional Details</h6>
            <hr class="my-3 horizontal dark" />

            <div class="row">
              <div class="col-md-6 my-1">
                <label class="form-label" htmlFor="addproduct-daily-limit">
                  Daily Sales Limit *
                </label>
                <argon-input
                  id="addproduct-daily-limit"
                  v-model="product.daily_limit"
                  type="number"
                  placeholder="Enter product daily limit"
                  is-required
                />
              </div>
              <div class="col-md-6 my-1">
                <label class="form-label" htmlFor="addproduct-total-limit">
                  Total Sales Limit *
                </label>
                <argon-input
                  id="addproduct-total-limit"
                  v-model="product.total_limit"
                  type="number"
                  placeholder="Enter product total limit"
                  is-required
                />
              </div>
            </div>
            <div class="row my-1">
              <div class="col-12 col-md-6 my-2">
                <label class="my-0">Expensive Product</label>
                <p class="text-xs form-text text-muted ms-1 my-1">
                  If you select this, the product won't show to the new members.
                </p>
                <div class="form-check form-switch ms-1 my-0">
                  <input v-model="product.expensive" class="form-check-input" type="checkbox" />
                </div>
              </div>
              <div class="col-12 col-md-6 my-2">
                <label class="my-0">Prime Only</label>
                <p class="text-xs form-text text-muted ms-1 my-1">Show only to prime marketers.</p>
                <div class="form-check form-switch ms-1 my-0">
                  <input v-model="product.prime_only" class="form-check-input" type="checkbox" />
                </div>
              </div>
            </div>
          </div>

          <!--    General Rules      -->
          <div class="mt-4 card card-body">
            <h6 class="mb-0">General Rules</h6>
            <hr class="my-3 horizontal dark" />

            <RuleCard
              v-for="(value, i) in product.general_rules"
              :key="i"
              :idx="i"
              name="general_rules"
              :value="value"
            />

            <div class="row mt-3">
              <div class="col-9 col-md-11">
                <argon-input type="text" v-model="general_rules" placeholder="Add another point" />
              </div>
              <div class="col-3 col-md-1">
                <argon-button class="w-100" @click="addRule('general_rules')">+</argon-button>
              </div>
            </div>
          </div>

          <!--    Instructions for Marketer      -->
          <div class="mt-4 card card-body">
            <h6 class="mb-0">Instructions for Marketer</h6>
            <hr class="my-3 horizontal dark" />

            <RuleCard
              v-for="(value, i) in product.instructions"
              :key="i"
              :idx="i"
              name="instructions"
              :value="value"
            />

            <div class="row mt-3">
              <div class="col-9 col-md-11">
                <argon-input type="text" v-model="instructions" placeholder="Add another point" />
              </div>
              <div class="col-3 col-md-1">
                <argon-button class="w-100" @click="addRule('instructions')">+</argon-button>
              </div>
            </div>
          </div>

          <!--    Commission Policy      -->
          <div class="mt-4 card card-body">
            <h6 class="mb-0">Commission Policy</h6>
            <hr class="my-3 horizontal dark" />

            <RuleCard
              v-for="(value, i) in product.commission_policy"
              :key="i"
              :idx="i"
              name="commission_policy"
              :value="value"
            />

            <div class="row mt-3">
              <div class="col-9 col-md-11">
                <argon-input
                  type="text"
                  v-model="commission_policy"
                  placeholder="Add another point"
                />
              </div>
              <div class="col-3 col-md-1">
                <argon-button class="w-100" @click="addRule('commission_policy')">+</argon-button>
              </div>
            </div>
          </div>

          <!--    Submit/Cancel      -->
          <div class="mt-4 d-flex justify-content-end">
            <button type="button" name="button" class="m-0 btn btn-light" @click="$router.go(-1)">
              Cancel
            </button>
            <button
              type="button"
              name="button"
              class="m-0 btn bg-gradient-primary ms-2"
              @click="handleSubmit"
              :disabled="loading"
            >
              <i v-if="loading" class="fa fa-refresh fa-spin" />
              <span v-else>Create Product</span>
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Bulk Import Modal -->
    <div
      class="modal fade"
      :class="{ show: showBulkImportModal }"
      style="display: block"
      v-if="showBulkImportModal"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Bulk Import Products</h5>
            <button
              type="button"
              class="btn-close"
              @click="showBulkImportModal = false"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <p>Import multiple products at once using an Excel file.</p>
            <div class="mb-3">
              <a
                :href="`${baseURL}/products/import-template`"
                class="btn btn-sm btn-outline-primary"
                target="_blank"
              >
                <i class="fas fa-download me-2"></i>
                Download Template
              </a>
              <small class="d-block mt-2 text-sm text-muted">
                Download and fill this template with your product details.
              </small>
            </div>

            <div class="mb-3">
              <label for="import-file" class="form-label">Upload Your Excel File</label>
              <input
                type="file"
                class="form-control"
                id="import-file"
                @change="handleFileChange"
                accept=".xlsx, .xls"
                :disabled="importLoading"
              />
              <small class="text-muted">Only Excel files (.xlsx, .xls) are supported</small>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" @click="showBulkImportModal = false">
              Close
            </button>
            <button
              type="button"
              class="btn bg-gradient-primary"
              @click="handleBulkImport"
              :disabled="!selectedFile || importLoading"
            >
              <i v-if="importLoading" class="fa fa-refresh fa-spin me-2"></i>
              <span>Import Products</span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-backdrop fade show" v-if="showBulkImportModal"></div>
  </div>
</template>

<script>
import ArgonInput from '@/components/ArgonInput.vue';
import ArgonSelect from '@/components/ArgonSelect.vue';
import ArgonButton from '@/components/ArgonButton.vue';
import RuleCard from '@/components/Rule.vue';
import currencies from '@/utils/currencies';
import { useToast } from 'vue-toastification';
const toast = useToast();
import marketPlaces from '@/utils/marketPlaces';
import categories from '@/utils/categories';
import reviewTypes from '@/utils/reviewTypes';
import commissionTypes from '@/utils/commissionTypes';
import validateCommission, { commissionStructure } from '@/utils/commissionValidator';
import removeEmptyFields from '@/utils/removeEmptyFields';
import createFormData from '@/utils/createFormData';
import to from 'await-to-js';

export default {
  name: 'NewProduct',
  components: {
    ArgonButton,
    RuleCard,
    ArgonInput,
    ArgonSelect,
  },
  inject: ['store', 'actions'],
  watch: {
    'product.review_type'() {
      const product = this.product;
      const { review_type } = this.product;

      if (['Feedback', 'Rating', 'No Review'].includes(product.review_type)) {
        this.product.commission = {
          marketer: commissionStructure[review_type].marketer || '',
          portal: commissionStructure[review_type].portal || '',
        };
      } else {
        this.product.commission = {
          text: {
            marketer: commissionStructure[review_type].text.marketer || '',
            portal: commissionStructure[review_type].text.portal || '',
          },
          picture: {
            marketer: commissionStructure[review_type].picture.marketer || '',
            portal: commissionStructure[review_type].picture.portal || '',
          },
          video: {
            marketer: commissionStructure[review_type].video.marketer || '',
            portal: commissionStructure[review_type].video.portal || '',
          },
        };
      }
    },
    'store.user'() {
      this.setSellerCodes();
    },
  },
  data() {
    return {
      loading: false,
      currencies,
      marketPlaces,
      categories,
      reviewTypes,
      commissionTypes,
      commissionStructure,
      seller_codes: [],
      custom_seller_code: false,
      product: {
        amz_marketplace: 'United States',
        market_type: 'Amazon',
        category: '',
        seller_code: '',
        amz_asin: '',
        amz_link: '',
        sold_by: '',
        brand_name: '',
        price: '',
        keywords: '',
        product_image: '',
        amz_screenshot: '',
        review_type: 'Review',
        commission_type: 'Text',
        commission: {
          text: {
            marketer: commissionStructure.Review.text.marketer,
            portal: commissionStructure.Review.text.portal,
          },
          picture: {
            marketer: commissionStructure.Review.picture.marketer,
            portal: commissionStructure.Review.picture.portal,
          },
          video: {
            marketer: commissionStructure.Review.video.marketer,
            portal: commissionStructure.Review.video.portal,
          },
        },
        daily_limit: '',
        total_limit: '',
        expensive: false,
        prime_only: false,
        general_rules: [
          'Search by Keyword only.',
          '5 star rating + text review of minimum 3-4 lines is required.',
          'Review must be submitted after 6-8 days of shipment delivered.',
          'Once the live/published review is submitted for refund, the refund will be processed in 7 business/working days (Excluding weekends & public holidays).',
          'Please do not use coupon.',
          'Please do not use gift card.',
          'Please make sure to buy from correct Store otherwise order will not be accepted.',
          "Please make sure you haven't previously bought from the same store otherwise order will not be accepted.",
        ],
        instructions: [
          'Refund will be processed after live/published review.',
          'Full product cost will be refunded.',
          'Shipping Fee is not covered.',
          'Once the live/published Review is submitted to Manager, the refund will be processed in 7 working days.',
        ],
        commission_policy: [
          'Commission will be paid on Refunded Orders only.',
          'Commission will not be paid on Deleted Reviews.',
          'Commission will not be paid on Seller Feedback.',
          'Commission will not be paid on Disputed Orders.',
        ],
      },
      general_rules: '',
      instructions: '',
      commission_policy: '',
      baseURL: process.env.VUE_APP_API_URL,
      showBulkImportModal: false,
      selectedFile: null,
      importLoading: false,
    };
  },
  mounted() {
    this.setSellerCodes();
  },
  methods: {
    async handleSubmit() {
      let data = removeEmptyFields({ ...this.product });

      const validated = [
        'amz_marketplace',
        'market_type',
        'category',
        'sold_by',
        'brand_name',
        'keywords',
        'product_image',
        'review_type',
        'daily_limit',
        'total_limit',
      ].every((val) => !!data[val]);

      if (!validated) {
        return toast.error('Fields with * are required');
      }

      if (parseInt(data.daily_limit) > parseInt(data.total_limit)) {
        return toast.error('Daily limit must be lesser than total limit');
      }

      try {
        await validateCommission(data.review_type, data.commission);
      } catch (err) {
        return toast.error(err.message);
      }

      const formData = createFormData(data);

      this.loading = true;
      const [, res] = await to(this.actions.api('post', '/products', formData));
      this.loading = false;

      if (res?.product) {
        toast.success('Product has been created');
        window.scrollTo(0, 0);
        document.getElementById('addproduct-product-image').value = '';
        document.getElementById('addproduct-amz-screenshot').value = '';

        this.store.user = res.user;
        this.product = {
          ...this.product,
          seller_code: '',
          amz_asin: '',
          amz_link: '',
          sold_by: '',
          brand_name: '',
          price: '',
          keywords: '',
          product_image: '',
          amz_screenshot: '',
          daily_limit: '',
          total_limit: '',
          expensive: false,
          prime_only: false,
        };
      }
    },
    setSellerCodes() {
      this.seller_codes = [];
      this.store.user?.other_data?.seller_codes?.forEach((code, idx) => {
        if (idx === 0) {
          this.seller_codes.push({ value: '', label: 'Select a seller code' });
        }
        this.seller_codes.push({ value: code, label: code });
      });
    },
    addRule(name) {
      if (this[name]) {
        this.product[name].push(this[name]);
        this[name] = '';
      }
    },
    removeRule(name, idx) {
      this.product[name].splice(idx, 1);
    },

    // New methods for bulk import
    handleFileChange(event) {
      this.selectedFile = event.target.files[0];
    },

    async handleBulkImport() {
      if (!this.selectedFile) return;

      this.importLoading = true;

      const formData = new FormData();
      formData.append('excel_file', this.selectedFile);

      const [, response] = await to(this.actions.api('post', '/products/import', formData));

      this.importLoading = false;
      document.getElementById('import-file').value = '';
      this.selectedFile = null;

      if (response?.success) {
        toast.success(response.message);
        this.showBulkImportModal = false;

        // Refresh user data to get updated seller codes
        const [, res] = await to(this.actions.api('get', '/auth'));
        if (res?.user) {
          this.store.user = res.user;
        }
      }
    },
  },
};
</script>

<style scoped>
.modal {
  background-color: rgba(0, 0, 0, 0.5);
}
</style>
