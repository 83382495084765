export const commissionStructure = {
  Review: {
    text: {
      marketer: 800,
      portal: 150,
    },
    picture: {
      marketer: 800,
      portal: 150,
    },
    video: {
      marketer: 800,
      portal: 150,
    },
  },
  'No Review': {
    marketer: 200,
    portal: 75,
  },
  'Top Review': {
    text: {
      marketer: 0,
      portal: 150,
    },
    picture: {
      marketer: 0,
      portal: 150,
    },
    video: {
      marketer: 1000,
      portal: 150,
    },
  },
  Feedback: {
    marketer: 250,
    portal: 75,
  },
  Rating: {
    marketer: 300,
    portal: 100,
  },
  'High Commission': {
    text: {
      marketer: 0,
      portal: 150,
    },
    picture: {
      marketer: 0,
      portal: 150,
    },
    video: {
      marketer: 0,
      portal: 150,
    },
  },
};

// Define required properties based on the type
const requiredProperties = {
  Review: {
    text: ['marketer', 'portal'],
    picture: ['marketer', 'portal'],
    video: ['marketer', 'portal'],
  },
  'Top Review': {
    text: ['marketer', 'portal'],
    picture: ['marketer', 'portal'],
    video: ['marketer', 'portal'],
  },
  'High Commission': {
    text: ['marketer', 'portal'],
    picture: ['marketer', 'portal'],
    video: ['marketer', 'portal'],
  },
  // For types without subtypes, an empty array indicates no specific sub-properties are required
  'No Review': ['marketer', 'portal'],
  Feedback: ['marketer', 'portal'],
  Rating: ['marketer', 'portal'],
};

export default function validateCommission(type, commission) {
  // Validate the presence of required properties
  const typeRequirements = requiredProperties[type];
  if (!typeRequirements) {
    throw new Error('Invalid commission type provided.');
  }

  if (Array.isArray(typeRequirements)) {
    // For types without subtypes
    typeRequirements.forEach((property) => {
      if (!commission[property]) {
        throw new Error(`Missing "${property}" property for "${type}" commission type.`);
      }
    });
  } else {
    // For types with subtypes
    Object.keys(typeRequirements).forEach((subType) => {
      if (!commission[subType]) {
        throw new Error(`Missing "${subType}" subtype for "${type}" commission type.`);
      }

      typeRequirements[subType].forEach((property) => {
        if (commission[subType][property] === undefined) {
          throw new Error(
            `Missing "${property}" property in "${subType}" for "${type}" commission type.`,
          );
        }
      });
    });
  }

  // Validate "Feedback", "Rating", and "No Review" together
  if (['Feedback', 'Rating', 'No Review'].includes(type)) {
    const requiredManager =
      commissionStructure[type]?.marketer || commissionStructure[type]?.marketer;
    const requiredPortal = commissionStructure[type]?.portal || commissionStructure[type]?.portal;

    if (commission.marketer < requiredManager || commission.portal < requiredPortal) {
      throw new Error(`Commission values for "${type}" are below the minimum requirements.`);
    }
    return `Commission is valid for "${type}".`;
  }

  // For other types with subtypes like text, picture, video
  const subTypes = ['text', 'picture', 'video'];
  subTypes.forEach((subType) => {
    if (commission[subType]) {
      const { marketer, portal } = commission[subType];
      const minManager = commissionStructure[type][subType]?.marketer;
      const minPortal = commissionStructure[type][subType]?.portal;

      // Check if the commission structure provided does not meet the minimum for the subtype
      if (marketer < minManager || portal < minPortal) {
        throw new Error(
          `Commission values for "${subType}" in "${type}" are below the minimum requirements.`,
        );
      }
    }
  });

  return `Commission is valid for "${type}".`;
}
