<template>
  <div
    class="modal fade"
    id="bulkUpdateModal"
    tabindex="-1"
    aria-labelledby="bulkUpdateModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="bulkUpdateModalLabel">Bulk Update Orders</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
            <i class="fas fa-times text-secondary"></i>
          </button>
        </div>
        <form @submit.prevent="handleSubmit">
          <div class="modal-body">
            <p>Update multiple orders at once by selecting a date range and status.</p>

            <div class="row mb-3">
              <div class="col-12 col-md-6">
                <label for="startDate" class="form-label">Start Date</label>
                <input
                  type="date"
                  class="form-control"
                  id="startDate"
                  v-model="formData.startDate"
                  required
                />
              </div>
              <div class="col-12 col-md-6">
                <label for="endDate" class="form-label">End Date</label>
                <input
                  type="date"
                  class="form-control"
                  id="endDate"
                  v-model="formData.endDate"
                  required
                />
              </div>
            </div>

            <div class="row mb-3">
              <div class="col-12 col-md-6">
                <label for="fromStatus" class="form-label">From Status</label>
                <argon-select
                  id="fromStatus"
                  v-model="formData.fromStatus"
                  :options="filterAvailableStatuses()"
                  :first-disabled="false"
                  :use-choices="false"
                  required
                />
              </div>
              <div class="col-12 col-md-6">
                <label for="toStatus" class="form-label">To Status</label>
                <argon-select
                  id="toStatus"
                  v-model="formData.toStatus"
                  :options="filterAvailableStatuses()"
                  :first-disabled="false"
                  :use-choices="false"
                  required
                />
              </div>
            </div>

            <div class="mb-3">
              <label for="comment" class="form-label">Comment (Optional)</label>
              <textarea
                class="form-control"
                id="comment"
                v-model="formData.comment"
                rows="2"
                placeholder="Add a comment for the status change"
              ></textarea>
            </div>
          </div>
          <div class="modal-footer">
            <button
              id="bulkUpdateModalClose"
              type="button"
              class="btn bg-gradient-secondary"
              data-bs-dismiss="modal"
            >
              Cancel
            </button>
            <button type="submit" class="btn bg-gradient-primary" :disabled="loading">
              <i v-if="loading" class="fa fa-refresh fa-spin me-1"></i>
              Update Orders
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import ArgonSelect from '@/components/ArgonSelect.vue';
import orderStatus from '@/utils/orderStatus.js';
import orderStatusRights from '@/utils/orderStatusRights.js';
import to from 'await-to-js';
import { useToast } from 'vue-toastification';

const toast = useToast();

export default {
  name: 'BulkUpdateModal',
  components: { ArgonSelect },
  inject: ['store', 'actions'],
  data() {
    return {
      formData: {
        startDate: this.formatDate(new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)), // 7 days ago
        endDate: this.formatDate(new Date()),
        fromStatus: '',
        toStatus: '',
        comment: '',
      },
      loading: false,
    };
  },
  methods: {
    formatDate(date) {
      return date.toISOString().split('T')[0];
    },
    filterAvailableStatuses() {
      // Filter status options based on user role
      if (!this.store.role) return [];

      const availableStatuses = orderStatusRights[this.store.role] || [];
      return orderStatus.filter(
        (status) => status.value !== '' && availableStatuses.includes(status.value),
      );
    },
    resetForm() {
      this.formData = {
        startDate: this.formatDate(new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)),
        endDate: this.formatDate(new Date()),
        fromStatus: '',
        toStatus: '',
        comment: '',
      };
    },
    async handleSubmit() {
      if (!this.formData.fromStatus || !this.formData.toStatus) {
        toast.error('Please select both from and to status');
        return;
      }

      if (new Date(this.formData.startDate) > new Date(this.formData.endDate)) {
        toast.error('Start date cannot be after end date');
        return;
      }

      this.loading = true;
      const [, response] = await to(this.actions.api('put', '/orders/bulk/update', this.formData));
      this.loading = false;

      if (response?.success) {
        toast.success(response.message);

        // Refresh the orders list
        this.$parent.fetchData();

        // Close modal after successful update with slight delay
        setTimeout(() => {
          document.getElementById('bulkUpdateModalClose')?.click();
          this.resetForm();
        }, 1000);
      }
    },
  },
};
</script>

<style scoped>
.modal-header .btn-close {
  padding: 0.5rem;
  margin: -0.5rem -0.5rem -0.5rem auto;
}
</style>
